<template>
  <div class="container">
    <div class="title" v-if="dataMsg">
            <span>
              {{dataMsg.carTitle}}
            </span>
    </div>
    <div class="content" v-if="dataMsg">
      <div class="left_content" :class="{'min_height': !swiperFlag}">
        <img src="../../assets/1dcw.gif" alt="" class="loading_gif" v-if="!swiperFlag">
        <div class="swiper_container" v-if='swiperFlag'>
          <div class="swiper" id="swiper-container">
            <div class="swiper-wrapper gallery-top">
              <div class="swiper-slide" v-for="(item, index) in dataMsg.imageList" :key="item+dataMsg.id">
                <div class="img_item" v-if="item">
                  <video v-if="dataMsg.carVideo && index === 0" :src="item" class="video-avatar"
                         controls="controls"></video>
                  <img :src="item" alt="" v-else>
                </div>
              </div>
            </div>
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
          </div>
          <div>
            <div class="swiper" id="thumbs" thumbsSlider="">
              <div class="swiper-wrapper gallery-thumbs">
                <div class="swiper-slide" v-for="(item, index) in dataMsg.imageList"
                     :key="item+dataMsg.id">
                  <div class="thumbs_img" v-if="item">
                    <video v-if="dataMsg.carVideo && index === 0" :src="item" muted
                           class="video-avatar"></video>
                    <img :src="item" alt="" v-else>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_content">
        <div class="name_container">
          <div class="name_content first_name">
            <span>{{dataMsg.licenseDate|| '--'}}</span>
            <div>
              <span class="name_desc">上牌时间</span>
            </div>
          </div>
          <div class="name_content border_after flex1">
            <span>{{dataMsg.roadHaulAbbr || '--'}}万公里</span>
            <span class="name_desc">表现里程</span>
          </div>
          <div class="name_content flex1">
            <span>{{dataMsg.fuelType |fuelType}}</span>
            <span class="name_desc">能源类型</span>
          </div>
        </div>

        <div class="start_price">
          <span class="label">起拍价</span>
          <span class="time">{{dataMsg.startPrice}}</span>
          <span class="symbol">元</span>
        </div>

        <div class="start_price range">
          <span class="label">加价幅度</span>
          <span class="time">{{dataMsg.deltaPrice}}</span>
          <span class="symbol">元</span>
        </div>

        <div class="times">
          <span class="label">倒计时</span>
          <div class="time">
            {{dataMsg.countDown|filterTime}}
          </div>
          <span class="symbol">:</span>
          <span class="time">{{second | filterTime}}</span>
          <!--<span class="time">0</span>-->
        </div>

        <div class="contend">
          <div class="label">
            竞拍价
          </div>
          <div class="content" :class="{'text_center': !priceList || priceList.length<2}" v-show="price">
            <div class="contend_container">
              <span :key="index" v-for="(item, index) in priceList" :class="'del_line'">{{item}}</span>
              <span class="anima" :class="{'animt' : fle}">{{price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no_data">
      <img src="../../assets/default_page.png" alt="">
      <div>
        <p>
          拍卖尚未开始， 请耐心等待...
        </p>
      </div>
    </div>
<!--                    <button @click="startTest(1)">1</button>-->
<!--                    <button @click="startTest(2)">2</button>-->
<!--                    <button @click="startTest(3)">3</button>-->
<!--                    <button @click="startTest(4)">4</button>-->
<!--                    <button @click="startTest(5)">5</button>-->
  </div>
</template>

<script>
  import jsonData from './data.json'
  import axios from 'axios'

  let [pic, pic2, pic3, pic4, pic5] = [null, null, null, null, null]
  const clearTimer = () => {
    /*
    * pic 倒计时
    * pic2 动效
    * pic3 swiper
    * pic4 pic5 倒计时
    * */
    // clearInterval(pic)
    clearTimeout(pic2)
    clearTimeout(pic5)
    clearTimeout(pic4)
  }
  export default {
    name: "home",
    filters: {
      filterTime(val = 0) {
        return Number(val) < 10 ? `0${val}` : val
      },
      fuelType(val) {
        return ['--', '电动车', '插电混动', '燃油车'][val] || '--'
      }
    },
    data() {
      return {
        second: 0,
        dataMsg: null,
        swiperFlag: false,
        fle: false,
        lockReconnect: false,
        timeout: 540000,        //9分钟发一次心跳
        timeoutObj: null,
        serverTimeoutObj: null,
        price: '',
        priceList: [],
        websock: null,
      }
    },
    beforeDestroy() {
      if (this.websock) this.websock.close()
      clearTimer()
    },
    methods: {
      reset() {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        this.start()
      },
      start() {
        this.timeoutObj = setTimeout(() => {
          this.websock.send("ping");
          this.serverTimeoutObj = setTimeout(() => {
            this.websock.close();
          }, this.timeout)
        }, this.timeout)
      },
      startTest(state) {
        this.getMessageText(jsonData[state])
      },
      reconnect() {
        if (this.lockReconnect) return;
        this.lockReconnect = true;
        setTimeout(() => {     //没连接上会一直重连，设置延迟避免请求过多
          this.initWebScoket();
          this.lockReconnect = false;
        }, 5000);
      },
      initWebScoket() {
        this.websock = new WebSocket(`${this.domain.ws}/${Math.floor(Math.random() * 100)}`)
        this.websock.onmessage = this.getMessage
        this.websock.onopen = this.onopen
        this.websock.onerror = this.onerror
        this.websock.onclose = this.onclose
      },
      onopen(e) {
        console.log(`连接成功`, e);
        this.reset()
      },
      getMessage(e) {
        this.reset()
        console.log(e.data)
        try {
          this.getMessageText(JSON.parse(e.data))
        } catch (err) {
          // console.log(err);
        }
      },
      swiperTopThumbs() {
        clearTimeout(pic3)
        pic3 = setTimeout(() => {
          this.swiperFlag = true
          this.$nextTick(() => {
            let thumbsSwiper = new window.Swiper('#thumbs', {
              spaceBetween: 10,
              slidesPerView: 'auto',
              watchSlidesVisibility: true,//防止不可点击
              loop: false
            })
            new window.Swiper('#swiper-container', {
              // spaceBetween: 10,
              watchSlidesVisibility: true,//防止不可点击
              loop: false,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
              thumbs: {
                swiper: thumbsSwiper,
              }
            })
          })
        }, 500)
      },
      getMessageText(data) {
        // 0- "连接成功" 1- "更新车辆信息" 2- "更新倒计时" 3- "报价" 4- "开始拍卖" 5- "结束拍卖"
        const {content} = data
        const {id, price, countdown, status, finalPrice} = content
        const type = Number(data.type)
        if (this.dataMsg && id !== this.dataMsg.id) {
          const params = {id, type}
          this.dataMsg.id = id
          this.websock.send(JSON.stringify(params))
        }
        if (type === 1) {
          this.priceList = []
          this.price = Number(status) === 2 ? finalPrice : ''
          clearTimer()
          this.swiperFlag = false
          let imageList = content.carVideo ? [content.carVideo, ...content.imageList] : content.imageList
          this.dataMsg = {...content, imageList}
          this.second = 0
          this.swiperTopThumbs()
        }
        if (type === 2) {
          clearTimer()
          this.second = 0
          this.dataMsg.countDown = countdown
        }
        if (type === 3) {
          clearTimeout(pic2)
          this.fle = true
          const price_or = this.price
          this.price = Number(price) <= 0 ? '': price
          if (price) {
            let priceList = price_or ? [...this.priceList, price_or] : [...this.priceList]
            if (priceList.length > 8) priceList.shift()
            this.priceList = priceList
          }
          pic2 = setTimeout(() => {
            this.fle = false
          }, 700)
        }
        if (type === 4) {
          this.countDown(Number(countdown))
        }
        if (type === 5) {
          this.auctionEnd()
        }
      },
      auctionEnd() {
        clearTimer()
        axios.post(`${this.domain.http}/api/auction/finish`, {id: this.dataMsg.id})
      },
      onerror(e) {
        console.log('建立失败', e);
        this.reconnect()
      },
      setShowTime(leftTime) {
        leftTime = leftTime > 0 ? leftTime : 0
        //小10，显示两位数
        let m = Math.floor(leftTime / 60)
        let s = Math.floor(leftTime % 60)
        this.dataMsg.countDown = m
        this.second = s
      },
      countDown(second) {
        clearTimer()
        let leftTime = second * 60
        let endTime = new Date().getTime() / 1000 + leftTime
        const fixed = () => {
          //1.设置倒计时
          if (leftTime > 0) {
            leftTime--
            this.setShowTime(leftTime)
          } else {
            //4、倒计时结束，暂停。（用户端操作  结束命令）
            return this.auctionEnd()
          }
          //2.校准事件，避免不精准(使用本地时间，避免精准、浏览器休眠问题)
          leftTime = endTime - new Date().getTime() / 1000
          pic4 = setTimeout(fixed, 1000);
        }
        pic5 = setTimeout(fixed, 1000);
      },
      onclose(e) {
        console.log('断开链接', e);
      },
    },
    computed: {
      domain() {
        const {VUE_APP_CURENV, NODE_ENV} = process.env
        if (VUE_APP_CURENV === 'test' || NODE_ENV === 'development') {
          return {
            ws: 'ws://bapisit.shuimuche.com/websocket',
            http: 'http://bapisit.shuimuche.com'
          }
        }
        return {ws: 'wss://bapi.shuimuche.com/websocket', http: 'https://bapi.shuimuche.com'}
      }
    },
    created() {
      const {NODE_ENV} = process.env
      if (NODE_ENV === 'development') {
        this.startTest(1)
      } else {
        this.initWebScoket()
      }

    }
  }
</script>

<style scoped lang="scss">
  $swiper_height: 6.3rem;
  $swiper_width: 8.52rem;
  .swiper_container {
    width: $swiper_width;
    display: flex;
    flex-direction: column;
    /*min-width: 580px;*/
    /*justify-content: space-around;*/
    justify-content: space-between;


    .img_item {
      width: $swiper_width;
      height: 100%;

      img, .video-avatar {
        display: block;
        width: 100%;
        height: 100%;
      }

    }

    .swiper-button-white {
      color: #fff;
    }
  }

  .container {
    /*min-width: 1080px;*/
    max-width: 2300px;
    /*width: 100vw;*/
    /*overflow: hidden;*/
    margin: 0 auto;

    /*padding-left: 32px;*/
    padding-right: 164px;
    padding-left: 164px;
    box-sizing: border-box;

    .gallery-top {
      /*min-height: 420px;*/
      /*max-height: 32vw;*/
      height: $swiper_height;
      width: $swiper_width;
    }

    .gallery-thumbs {
      /*height: 1.19rem;*/
      box-sizing: border-box;
      /*padding-top: .26rem;*/
    }

    .gallery-thumbs .swiper-slide {
      width: 1.62rem;
      /*height: 100%;*/
      height: 1.1rem;
    }

    .swiper_slide {
      width: 1.60rem;
      /*height: 100%;*/
      height: 1rem;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
      border: 4px solid #0CC598;
      box-sizing: border-box;
    }

    .thumbs_img {
      width: 100%;
      height: 100%;

      img, .video-avatar {
        height: 100%;
        display: block;
        width: 100%;
      }
    }
  }

  .title {
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: .24rem;
    justify-content: center;

    span {
      color: #1d312d;
      font-weight: bold;
      font-size: .8rem;
      padding: 0 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .content {
    display: flex;
    width: 100%;
    margin-top: .2rem;
    box-sizing: border-box;

    .right_content, .left_content {
      /*width: 50%;*/
    }

    .left_content {
      display: flex;
      justify-content: flex-end;

      &.min_height {
        width: $swiper_width;
        height: 100%;

        > .loading_gif {
          margin: 0 auto;
          display: block;
          width: 88px;
          margin-top: 200px;
        }
      }
    }

    .right_content {
      flex: 1;

      .name_container {
        display: flex;

        .name_content {
          &.flex1 {
            flex: 1;
          }

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > span {
            color: #1d312d;
            font-weight: bold;
            font-size: .44rem;
          }

          .name_desc {
            font-weight: normal;
            color: #a1adac;
            font-size: .3rem;
            margin-top: .06rem;
          }
        }

        .first_name {
          align-items: start;
          padding-left: .2rem;
          padding-right: .21rem;

          > div {
            display: flex;
            width: 100%;
            justify-content: center;
          }

          .name_desc {
            text-align: center;
          }
        }

        .border_after {
          position: relative;
          box-sizing: border-box;

          &::after, &::before {
            position: absolute;
            content: '';
            width: 2px;
            height: .45rem;
            background: #e6e6e6;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
          }
        }
      }

      .times, .start_price {
        padding-left: .2rem;
        box-sizing: border-box;
        margin-top: .36rem;
        display: flex;
        align-items: center;

        .label {
          font-weight: bold;
          color: #1d312d;
          font-size: .42rem;
          /*padding-right: 32px;*/
          position: relative;
          margin-right: 30px;
          width: 4.7em;

          &::before {
            position: absolute;
            content: '';
            width: 2px;
            height: .26rem;
            background: #606d6a;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        .time {
          color: #fc1717;
          font-size: .62rem;
          font-weight: bold;
        }

        .symbol {
          color: #fc1717;
          font-size: .62rem;
          margin: 0 20px;
          padding-bottom: 10px;
          padding-right: 22px;
        }

        &.range {
          .time, .symbol {
            color: rgb(102, 102, 102);
          }
        }
      }

      .times {
        vertical-align: middle;
        margin-top: .1rem;

        .symbol {
          vertical-align: middle;
          padding-bottom: 15px;
          margin: 0 2px;
          font-size: .77rem;
        }

        .time {
          font-size: .77rem;
          letter-spacing: .28rem;
        }
      }

      .start_price {
        margin-top: .1rem;

        > .symbol {
          font-weight: bold;
          padding-bottom: 2px;
          margin-left: 10px;
          font-size: .5rem;
        }
      }


      .contend {
        margin-top: .28rem;
        height: 3rem;
        background: #fffdf9;
        border: 2px solid #fcf2d5;
        margin-left: .2rem;
        display: flex;
        /*width: 100%;*/
        box-sizing: border-box;
        width: 90%;
        align-items: center;

        .label {
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: .42rem;
          min-width: 6em;
          height: 100%;
          color: #1d312d;
        }

        .content {
          overflow: hidden;
          /*height: 100%;*/
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 10px;
          box-sizing: border-box;
          flex-direction: column;
          margin-top: 0;
          /*background: pink;*/
          position: relative;
          height: 3.2em;
          /*transition: all .4s ease;*/
          font-size: .8rem;

          &.text_center {
            justify-content: center;

            > .contend_container {
              position: static;
            }
          }

          .contend_container {
            position: absolute;
            display: flex;
            font-size: .8rem;
            flex-direction: column;
            align-items: center;
            bottom: 0;
            transition: all .6s ease;
          }

          span {
            color: #fc1717;
            font-size: .8rem;
            letter-spacing: 2px;
            /*padding-bottom: 8px;*/
            box-sizing: border-box;
          }

          .del_line {
            color: #A7A7A7;
            transition: height .6s ease;
            font-size: .58rem;
            line-height: .65rem;
            /*font-size: 58px;*/
            text-decoration: line-through;
          }

          .anima {
            overflow: hidden;
            height: 1.2rem;
            font-weight: bold;
          }

          .animt {
            height: 0;
            animation: h .6s both;
          }

          @keyframes h {
            0% {
              height: 0;
            }
            100% {
              height: 1.2rem;
            }
          }
        }
      }
    }
  }

  .no_data {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 600px;
    }

    div {
      display: flex;
      align-content: center;

      p {
        color: #737979;
        font-size: 45px;
      }
    }
  }
</style>
